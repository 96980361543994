<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap mb-2">
        <div>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mr-1 float-left"
            @click="onRefresh()"
          >
            <feather-icon icon="DatabaseIcon" class="mr-50" />
            <span class="align-middle">Refresh Data</span>
          </b-button>
        </div>
      </div>

    <b-card
      text-variant="center"
      :img-src="require('@/assets/images/banner/banner.svg')"
      overlay
      img-alt="card img"
    >
      <h1
        style="font-size: 2.4vw"
        class="align-self-center mt-2 text-primary font-weight-bolder"
      >
        Kode Kuesioner
      </h1>
      <b-form class="pl-5 pr-5">
        <b-form-group>
          <b-form-input
            id="code"
            placeholder="Masukan Kode Kuesioner"
            readonly
            v-model="code"
            style="text-align: center; font-size: 2vw"
          />
        </b-form-group>
      </b-form>

      <h1
        style="font-size: 2.4vw"
        class="align-self-center text-primary font-weight-bolder"
      >
        Jumlah Mahasiswa
      </h1>
      <b-form class="pl-5 pr-5">
        <b-form-group>
          <b-form-input
            id="code"
            placeholder="Masukan jumlah mahasiswa"
            readonly
            v-model="model.studentCompletedCount"
            style="text-align: center; font-size: 2vw"
          />
        </b-form-group>
      </b-form>
    </b-card>

    <div>
      <span v-for="student in model.students" v-bind:key="student" style="margin-left: 5px;">
        <b-badge href="#" variant="primary">{{ student }}</b-badge>
      </span>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BForm,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardTitle,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BForm,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      title: "Riwayat Pengisian Kuesioner",
      api: "questionnaire-session/progress",
      code: "",
      model: {},
    };
  },
  mounted() {
    const _ = this;
    _.init();
    _.loadData();
  },
  methods: {
    init() {
      const _ = this;
      _.code = this.$route.params.id;
    },
    loadData() {
      const _ = this;
      _.$axios.get(`${_.api}/${_.$route.params.id}`).then((res) => {
        const consume = res.data;
        _.model = consume.data;
      });
    },
    onRefresh() {
      const _ =this;
      _.loadData();
    }
  },
};
</script>
